import React from 'react';
import { Link } from 'react-router-dom';
import {
  Root,
  Image,
  Content,
  Title,
  InfoContainer,
  TagsContainer,
  Tag,
  Copyright,
} from './styles';

import { INews, ITags } from '../../models/news';

interface ICard {
  title: string;
  src: string;
  link: string;
  date: number;
  type?: 'small';
  tags?: ITags[];
  copyright?: string;
  onClick?: () => void;
}

function Card(props: ICard) {
  const { title, src, link, date, type, tags, onClick, copyright } = props;


  const newsDate = new Date(date);

  const getDate = () => {
    let day = newsDate.getDate();
    let month = newsDate.getMonth() + 1;
    let year = newsDate.getFullYear();

    return `${day < 10 ? "0" + day : day}.${month < 10 ? "0" + month : month}.${year}`;
  }

  const getTime = () => {
    let hours = newsDate.getHours();
    let minutes = newsDate.getMinutes();

    return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`;
  }

  return (
    <Root type={type} onClick={onClick}>
      <Link to={link}>
        <Image alt={title} src={src} type={type} />
        {copyright && <Copyright>Фото: {copyright}</Copyright>}
        <Content type={type}>
          <Title>{title}</Title>
          <InfoContainer>
            {getDate()} &bull; {getTime()}
          </InfoContainer>
          {
            tags &&
            <TagsContainer>
              {
                tags.map((item, i) => <Tag key={i}>{item.title}</Tag>)
              }
            </TagsContainer>
          }
        </Content>
      </Link>
    </Root>
  );
}

export default Card;
