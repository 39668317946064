import { AxiosResponse } from 'axios';
import axios from '../axios';
import { IParamsNewsQueryGET } from '../models/fetch';

class NewsApi {
  static fetchNewsList(query: IParamsNewsQueryGET = {}) {
    return axios.get('/news/list', { params: query })
      .then((response: AxiosResponse) => response)
      .catch(({ response }) => ({ ...response }));
  }

  static fetchOneNews(slug: string) {
    return axios.get(`/news/list/${slug}`)
      .then((response: AxiosResponse) => response)
      .catch(({ response }) => ({ ...response }));
  }
};

export default NewsApi;
