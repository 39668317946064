import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useDrag } from '../../hooks';
import Container from '../Container';
import Card from '../Card';
import Button from '../Button';
import { Root, Title, TagsContainer, NewsContainer } from './styles';
import { INews, ITags } from '../../models/news';

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
interface ITagsThemes extends ITags {
  isSelected: boolean;
}
interface IProps {
  tags: ITagsThemes[];
  setTags: any; // Function
  news: INews[];
}

function ThemesNews(props: IProps) {
  const { news, tags, setTags } = props;

  // Drag for ScrollMenu
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (
    ev: React.MouseEvent
  ) => dragMove(ev, (posDiff) => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollLeft += posDiff;
    }
  });

  const handleItemClick = () => (event: React.MouseEvent<HTMLElement>) => {
    if (dragging) {
      return false;
    }

    setTags(tags.map(item => {
      if (item.title === event.currentTarget.innerHTML) {
        return { ...item, isSelected: !item.isSelected };
      }

      return item;
    }));
  }

  const getUrl = (url: string) => `${process.env.REACT_APP_ADMIN_URL}${url}`;

  return (
    <Root>
      <Container>
        <Title>Новости по темам</Title>
        <TagsContainer>
          <ScrollMenu
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
          >
            {
              tags.map((item, i) => <Button color={item.isSelected ? "blue" : "light-blue"} key={i} onClick={() => handleItemClick()}>{item.title}</Button>)
            }
          </ScrollMenu>
        </TagsContainer>
        <NewsContainer>
          {
            news.map(
              (item, i) =>
                <Card
                  key={i}
                  title={item.title}
                  src={getUrl(item.image.path)}
                  link={`/news/${item.slug}`}
                  date={item.date}
                  tags={item.tags}
                  copyright={item.image.info}
                />
            )
          }
        </NewsContainer>
      </Container>
    </Root>
  );
}

export default ThemesNews;
