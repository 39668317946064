import styled from "styled-components";

export const Root = styled.section`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  border-radius: 10px;

  @media ${({ theme }) => theme.media.tablet} {
    padding: 20px;
  }
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  @media ${({ theme }) => theme.media.laptop} {
    font-size: 24px;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

interface ITag {
  selected?: boolean;
}

export const Tag = styled.div<ITag>`
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px;
  font-weight: bold;
  color: ${
  ({ selected, theme }) => selected ?
    theme.colors.white
    : theme.colors.black
  };
  background-color: ${
  ({ selected, theme }) => selected ?
    theme.colors.blue
    : theme.colors.lightBlue
  };
  border-radius: 10px;
  cursor: pointer;
`;
