import styled from 'styled-components';

interface IProps {
  type?: 'small',
}

export const Root = styled.article<IProps>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  overflow: hidden;

  ${({ type, theme }) => type === 'small' && (`
    margin-top: 20px;
    border-bottom: 1px solid ${theme.colors.darkGray};
    border-radius: 0;
  `)}

  & > a {
    display: flex;
    flex-direction: ${({ type }) => type === 'small' ? 'row-reverse' : 'column'};
    text-decoration: none;

    ${({ type, theme }) => type === 'small' && (`
      justify-content: space-between;
    `)}
  }
`;

export const Image = styled.img<IProps>`
  width: 100%;
  ${({ type }) => type === 'small' && (`
    margin-left: 10px;
    width: 60px;
    height: 60px;
    border-radius: 10px;
  `)}

  @media ${({ theme }) => theme.media.desktop} {
    ${({ type }) => type === 'small' && (`
      width: 80px;
      height: 80px;
    `)}
  }
`;

export const Content = styled.div<IProps>`
  padding: 10px;

  ${({ type, theme }) => type === 'small' && (`
    padding-bottom: 20px;
  `)}
`;

export const Title = styled.h1`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  transition: .3s;

  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const InfoContainer = styled.div`
  margin-top: 20px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};
`;

export const TagsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled.span`
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 10px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.lightBlue};
  border-radius: 10px;

  &:last-child {
    margin-right: 0;
  }

  @media ${({ theme }) => theme.media.desctop} {
    font-size: 16px;
  }
`;

export const Copyright = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px;
  font-size: 12px;
  color: #FFF;
  background: rgba(0, 0, 0, .3);
  border-radius: 5px;
  transition-duration: .3s;

  &:hover {
    background: rgba(0, 0, 0, .7);
  }
`;
