import React, { useEffect, useRef } from 'react';
import { Carousel } from '../../../../components';
import { INews } from '../../../../models/news';
import { Root, ImageContainer, Image } from './styles';

interface IProps {
  news: INews[];
}

function CarouselComponent(props: IProps) {
  const { news } = props;
  const images: any = useRef(null);
  const getUrl = (url: string) => `${process.env.REACT_APP_ADMIN_URL}${url}`;

  const setMaxHeight = (): any => {
    const imagesList = images.current?.querySelectorAll('.imagesContainer');
    if (!imagesList) return;
    let max = 0;
    let min = 10000
    for (const node of imagesList) {
      const height = node.querySelector('img').offsetHeight;
      if (height > max) max = height;
      if (height < min) min = height;
    }
    const resHeight = (max + min) / 2;
    if (resHeight >= 5000) return setTimeout(setMaxHeight, 500);
    for (const node of imagesList) {
      node.style['height'] = `${resHeight}px`;
      node.querySelector('img').style.height = `100%`;
    }
  }
  useEffect(() => { setMaxHeight() }, [images]);

  return (
    <Root ref={images}>
      {
        news.length && (
          <Carousel>
            {
              news.map((item, i) => (
                <div key={i}>
                  <ImageContainer className='imagesContainer'>
                    <Image src={getUrl(item.image.path)} alt={item.title} />
                  </ImageContainer>
                  <p className="legend">
                    {item.title}
                    <br />
                    <span>Фото: {item.image.info}</span>
                  </p>
                </div>
              ))
            }
          </Carousel>
        )
      }
    </Root>
  );
}

export default CarouselComponent;

