import { Link } from 'react-router-dom';
import { INews } from '../../models/news';
import Card from '../Card';
import { Root, Title, LinkContainer } from './styled';

interface IProps {
  title: string;
  linkMore?: string;
  news: INews[];
}

function SideNews(props: IProps) {
  const { title, linkMore, news } = props;

  const getUrl = (url: string) => `${process.env.REACT_APP_ADMIN_URL}${url}`;

  return (
    <Root>
      <Title>{title}</Title>
      {
        news.map(
          (item, i) =>
            <Card
              key={i}
              title={item.title}
              src={getUrl(item.image.path)}
              link={`/news/${item.slug}`}
              date={item.date}
              type="small"
            />)
      }
      {
        linkMore &&
        <LinkContainer>
          <Link to={linkMore}>
            Показать больше
          </Link>
        </LinkContainer>
      }
    </Root>
  );
}

export default SideNews;
