import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTags } from '../../store/AC/tags';
import { fetchSearchNews } from '../../store/AC/news';
import { fetchCategories } from '../../store/AC/categories';
import { IRootState, ITagsRedux, ICategoriesRedux, INewsRedux } from '../../models/state';

import { Header, Container, Footer, TagsCloud } from '../../components';
import { SearchBlock, SelectedCategories, NewsList } from './components';
import { Root, Title, Content, LeftContainer, RightContainer } from './styles';
import { ICategories } from '../../models/categories';

export interface IState {
  search: string;
  categoryType: string;
  tags: string[];
  categories: string[];
}

function Search() {
  const [state, setState] = useState<IState>({
    search: '',
    categoryType: 'Все',
    tags: [],
    categories: [],
  });

  const dispatch = useDispatch();
  const tags = useSelector<IRootState, ITagsRedux>((state) => state.tags);
  const categories = useSelector<IRootState, ICategoriesRedux>((state) => state.categories);
  const news = useSelector<IRootState, INewsRedux>((state) => state.news);

  useEffect(() => {
    document.title = 'Поиск новостей';
  }, []);

  useEffect(() => {
    if (!tags.list.length) {
      dispatch(fetchTags());
    }

    if (!categories.list.length) {
      dispatch(fetchCategories());
    }
  }, [tags.list.length, categories.list.length, dispatch]);

  // Tags
  const setTag = (tag: string) => {
    const index = state.tags.indexOf(tag);

    if (index === -1) {
      state.tags.push(tag);
      setState({ ...state });
    } else {
      setState({ ...state, tags: state.tags.filter(item => item !== tag) });
    }
  }
  
  // Categories
  const setCategory = (category: ICategories) => {
    const title = category.title;
    const index = state.categories.indexOf(title);
    

    if (index === -1) {
      state.categories.push(title);
      setState({ ...state, categories: state.categories });
    } else {
      setState({ ...state, categories: state.categories.filter(item => item !== title) });
    }
  };

  // SEARCH
  const search = () => {
    dispatch(fetchSearchNews({
      isPublished: true,
      ...(state.search && { title: state.search }),
      ...(state.tags.length && { tags: state.tags }),
      ...(state.categoryType !== 'Все' && { categories: state.categories }),
    }));
  }

  return (
    <>
      <Header />

      <Root>
        <Container>
          <Title>Расширенный поиск новостей</Title>
          <SelectedCategories
            list={categories.list}
            selectCategory={(category: ICategories) => setCategory(category)}
          />
          <Content>
            <LeftContainer>
              <SearchBlock
                setState={setState}
                state={state}
                search={search}
              />
              { news.search.length !== 0 && <NewsList list={news.search} /> }
            </LeftContainer>

            <RightContainer>
              <TagsCloud tagsList={tags.list} setTag={setTag} activeTags={state.tags} />
            </RightContainer>
          </Content>
        </Container>
      </Root>
      <Footer />
    </>
  );
}

export default Search;
