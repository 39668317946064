enum NewsActions {
  FETCH_MAIN_NEWS = 'FETCH_MAIN_NEWS',
  FETCH_MAIN_NEWS_SUCCESS = 'FETCH_MAIN_NEWS_SUCCESS',
  FETCH_MAIN_NEWS_FAIL = 'FETCH_MAIN_NEWS_FAIL',

  FETCH_SLIDER_NEWS = 'FETCH_SLIDER_NEWS',
  FETCH_SLIDER_NEWS_SUCCESS = 'FETCH_SLIDER_NEWS_SUCCESS',
  FETCH_SLIDER_NEWS_FAIL = 'FETCH_SLIDER_NEWS_FAIL',

  FETCH_HOT_NEWS = 'FETCH_HOT_NEWS',
  FETCH_HOT_NEWS_SUCCESS = 'FETCH_HOT_NEWS_SUCCESS',
  FETCH_HOT_NEWS_FAIL = 'FETCH_HOT_NEWS_FAIL',

  FETCH_POPULAR_NEWS = 'FETCH_POPULAR_NEWS',
  FETCH_POPULAR_NEWS_SUCCESS = 'FETCH_POPULAR_NEWS_SUCCESS',
  FETCH_POPULAR_NEWS_FAIL = 'FETCH_POPULAR_NEWS_FAIL',

  FETCH_THEMES_NEWS = 'FETCH_THEMES_NEWS',
  FETCH_THEMES_NEWS_SUCCESS = 'FETCH_THEMES_NEWS_SUCCESS',
  FETCH_THEMES_NEWS_FAIL = 'FETCH_THEMES_NEWS_FAIL',

  FETCH_SEARCH_NEWS = 'FETCH_SEARCH_NEWS',
  FETCH_SEARCH_NEWS_SUCCESS = 'FETCH_SEARCH_NEWS_SUCCESS',
  FETCH_SEARCH_NEWS_FAIL = 'FETCH_SEARCH_NEWS_FAIL',
}

export default NewsActions;
