import styled from 'styled-components';

export const Root = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 16px;
  

  @media ${({ theme }) => theme.media.tablet} {
    width: 754px;
    box-sizing: content-box;
  }

  @media ${({ theme }) => theme.media.laptop} {
    width: 1120px;
  }

  @media ${({ theme }) => theme.media.desctop} {
    width: 1480px;
  }
`;
