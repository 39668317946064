import { INews } from "../../models/news";
import { Root, DescriptionContainer, Image, Title, Text, DateContainer } from "./styles";
import { Link } from "react-router-dom";

function NewsItem(props: INews) {
  const { image, title, text, date: timestamp, slug } = props;

  const date = new Date(timestamp);

  const getDate = () => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    return `${day < 10 ? "0" + day : day}.${month < 10 ? "0" + month : month}.${year}`;
  }

  const getTime = () => {
    let hours = date.getHours();
    let minutes = date.getMinutes();

    return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`;
  }

  const getUrl = (url: string) => `${process.env.REACT_APP_ADMIN_URL}${url}`;

  return (

    <Root>
      <Link to={'/news/' + slug}>
        <Image src={getUrl(image.path)} />
        <DescriptionContainer>
          <Title>
            {title}
          </Title>
          <Text dangerouslySetInnerHTML={{ __html: text }} />
          <DateContainer>
            {getDate()} &bull; {getTime()}
          </DateContainer>
        </DescriptionContainer>
      </Link>
    </Root>
  );
}

export default NewsItem;
