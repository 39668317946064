import styled from 'styled-components';

export const Root = styled.section``;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  @media ${({ theme }) => theme.media.tablet} {
    font-size: 34px;
  }

  @media ${({ theme }) => theme.media.laptop} {
    font-size: 44px;
  }
`;

export const TagsContainer = styled.div`
  margin-top: 20px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  white-space: nowrap;

  @media ${({ theme }) => theme.media.tablet} {
    padding: 20px 25px;
  }

  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .react-horizontal-scrolling-menu--item  {
    margin-left: 10px;

    &:first-child: {
      margin-left: 0;
    }
  }
`;

export const NewsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-top: 20px;

  @media ${({ theme }) => theme.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${({ theme }) => theme.media.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
