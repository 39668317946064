import { call, put } from 'redux-saga/effects';
import NewsApi from '../../../API/NewsApi';
import { ResponseGenerator, IParamsNewsQueryGET } from '../../../models/fetch';
import { fetchThemesNewsSuccess, fetchThemesNewsFail } from '../../AC/news';

interface IParams {
  type: string;
  payload: IParamsNewsQueryGET;
}

function* fetchSliderNews(params: IParams) {
  try {
    const response: ResponseGenerator = yield call(NewsApi.fetchNewsList, params.payload);

    if (response.status === 200) {
      yield put(fetchThemesNewsSuccess(response.data));
    }
    
    else {
      yield put(fetchThemesNewsFail('Ошибка загрузки списка новостей'));
    }
  } catch(error) {
    console.log(error);
  }
}

export default fetchSliderNews;
