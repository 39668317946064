import { call, put } from 'redux-saga/effects';
import TagsApi from '../../../API/TagsApi';
import { ResponseGenerator, IParamsTagsQueryGET } from '../../../models/fetch';
import { fetchTagsSuccess, fetchTagsFail } from '../../AC/tags';

interface IParams {
  type: string;
  payload: IParamsTagsQueryGET;
}

function* fetchTags(params: IParams) {
  try {
    const response: ResponseGenerator = yield call(TagsApi.fetchTags, params.payload);

    if (response.status === 200) {
      yield put(fetchTagsSuccess(response.data));
    }
    
    else {
      yield put(fetchTagsFail('Ошибка загрузки списка тегов'));
    }
  } catch(error) {
    console.log(error);
  }
}

export default fetchTags;
