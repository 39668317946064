import React from 'react';
import { useLocation, Link } from "react-router-dom";
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import Button from '../Button';
import { useDrag } from '../../hooks';
import { Root } from './styles';
import { ICategories } from '../../models/categories';

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

interface IProps {
  list: ICategories[];
}

function Categories(props: IProps) {

  // Drag for ScrollMenu
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (
    ev: React.MouseEvent
  ) => dragMove(ev, (posDiff) => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollLeft += posDiff;
    }
  });

  const handleItemClick = () => () => {
    if (dragging) {
      return false;
    }
  }

  const { search } = useLocation();
  const category = new URLSearchParams(search).get('category') || 'Все';
  
  return (
    <Root>
      <ScrollMenu
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
      >
        {
          props.list.map(
            (item, i) =>
              <Link to={item.title === 'Все' ? '/' : `/categories?category=${item.title}`} key={i}>
                <Button
                  color={category === item.title ? 'blue' : 'default'}
                  onClick={handleItemClick()}
                >
                  {item.title}
                </Button>
              </Link>
          )
        }
      </ScrollMenu>
      {/* <Button color="pink">Горячие новости</Button> */}
    </Root>
  );
}

export default Categories;
