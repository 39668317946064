import { IParamsNewsQueryGET } from '../../models/fetch';
import { NewsActions } from '../actions';

export const fetchMainNews = (params: IParamsNewsQueryGET = { isPublished: true }) => ({ type: NewsActions.FETCH_MAIN_NEWS, payload: params });
export const fetchMainNewsSuccess = (news: any) => ({ type: NewsActions.FETCH_MAIN_NEWS_SUCCESS, payload: news });
export const fetchMainNewsFail = (error: string) => ({ type: NewsActions.FETCH_MAIN_NEWS_FAIL, payload: error });

export const fetchSliderNews = (params: IParamsNewsQueryGET = { isPublished: true, type: ['slider'] }) => ({ type: NewsActions.FETCH_SLIDER_NEWS, payload: params });
export const fetchSliderNewsSuccess = (news: any) => ({ type: NewsActions.FETCH_SLIDER_NEWS_SUCCESS, payload: news });
export const fetchSliderNewsFail = (error: string) => ({ type: NewsActions.FETCH_SLIDER_NEWS_FAIL, payload: error });

export const fetchHotNews = (params: IParamsNewsQueryGET = { isPublished: true, type: ['hot'] }) => ({ type: NewsActions.FETCH_HOT_NEWS, payload: params });
export const fetchHotNewsSuccess = (news: any) => ({ type: NewsActions.FETCH_HOT_NEWS_SUCCESS, payload: news });
export const fetchHotNewsFail = (error: string) => ({ type: NewsActions.FETCH_HOT_NEWS_FAIL, payload: error });

export const fetchPopularNews = (params: IParamsNewsQueryGET = { isPublished: true, type: ['popular'] }) => ({ type: NewsActions.FETCH_POPULAR_NEWS, payload: params });
export const fetchPopularNewsSuccess = (news: any) => ({ type: NewsActions.FETCH_POPULAR_NEWS_SUCCESS, payload: news });
export const fetchPopularNewsFail = (error: string) => ({ type: NewsActions.FETCH_POPULAR_NEWS_FAIL, payload: error });

export const fetchThemesNews = (params: IParamsNewsQueryGET = { isPublished: true, tags: [] }) => ({ type: NewsActions.FETCH_THEMES_NEWS, payload: params });
export const fetchThemesNewsSuccess = (news: any) => ({ type: NewsActions.FETCH_THEMES_NEWS_SUCCESS, payload: news });
export const fetchThemesNewsFail = (error: string) => ({ type: NewsActions.FETCH_THEMES_NEWS_FAIL, payload: error });

export const fetchSearchNews = (params: IParamsNewsQueryGET = { isPublished: true }) => ({ type: NewsActions.FETCH_SEARCH_NEWS, payload: params });
export const fetchSearchNewsSuccess = (news: any) => ({ type: NewsActions.FETCH_SEARCH_NEWS_SUCCESS, payload: news });
export const fetchSearchNewsFail = (error: string) => ({ type: NewsActions.FETCH_SEARCH_NEWS_FAIL, payload: error });
