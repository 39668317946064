import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useDrag } from '../../hooks';
import { INews } from '../../models/news';
import Container from '../Container';
import {
  Root,
  Title,
  ScrollMenuContainer,
  Card,
  ImageContainer,
  Image,
  TitleNews,
  TextNews,
  DateTime,
  Arrow,
  ArrowCotainer,
  Copyright,
} from './styled';

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);
  return (
    <ArrowCotainer type="left">
      <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        {'<'}
      </Arrow>
    </ArrowCotainer>
  );
}
function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  return (
    <ArrowCotainer type="right">
      <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
        {'>'}
      </Arrow>
    </ArrowCotainer>
  );
}

interface IProps {
  news: INews[];
}

function PopularNews(props: IProps) {

  // Drag for ScrollMenu
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (
    ev: React.MouseEvent
  ) => dragMove(ev, (posDiff) => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollLeft += posDiff;
    }
  });

  const handleItemClick = () => () => {
    if (dragging) {
      return false;
    }
  }



  const getDate = (timestamp: number) => {
    const date = new Date(timestamp);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    return `${day < 10 ? "0" + day : day}.${month < 10 ? "0" + month : month}.${year}`;
  }

  const getTime = (timestamp: number) => {
    const date = new Date(timestamp);
    let hours = date.getHours();
    let minutes = date.getMinutes();

    return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`;
  }

  const getUrl = (url: string) => `${process.env.REACT_APP_ADMIN_URL}${url}`;

  return (
    <Root>
      <Container>
        <Title>Популярные новости</Title>
        <ScrollMenuContainer>
          <ScrollMenu
            onMouseDown={() => dragStart}
            onMouseUp={() => dragStop}
            onMouseMove={handleDrag}
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
          >
            {
              props.news.map(
                (item, i) =>
                  <Card key={i} onClick={handleItemClick()}>
                    <ImageContainer>
                      <Image src={getUrl(item.image.path)} alt={item.title} />
                    </ImageContainer>
                    {item.image.info && <Copyright>Фото: {item.image.info}</Copyright>}
                    <TitleNews>{item.title}</TitleNews>
                    <TextNews dangerouslySetInnerHTML={{ __html: item.text }} />
                    <DateTime>{getDate(item.date)} &bull; {getTime(item.date)}</DateTime>
                  </Card>
              )
            }

          </ScrollMenu>
        </ScrollMenuContainer>
      </Container>
    </Root>
  );
}

export default PopularNews;
