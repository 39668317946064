import Container from '../Container';
import { Root, Image, LinksContainer, Link, HR, Organization } from './styled';
import logoSrc from '../../images/logo.svg';
import vkSrc from '../../images/vk.svg';

function Footer() {
  return (
    <Root>
      <Container>
        <Image alt="Сибирская Наука +" src={logoSrc} />
        <LinksContainer>
          <Link href="/" target="_blank">
            <img src={vkSrc} alt="vk.com" width="18px" />
          </Link>
        </LinksContainer>
        <HR />
        <Organization>
          ГПНТБ СО РАН 2022 - {new Date().getFullYear()}
        </Organization>
      </Container>
    </Root>
  );
}

export default Footer;
