import React from 'react';
import { Card } from '../../../../components';
import { Root } from './styles';
import { INews } from '../../../../models/news';

interface IProps {
  news: INews[];
}

function News(props: IProps) {
  const getUrl = (url: string) => `${process.env.REACT_APP_ADMIN_URL}${url}`;

  return (
    <Root>
      {
        props.news.map(
          (item, i) =>
            <Card
              key={i}
              title={item.title}
              src={getUrl(item.image.path)}
              copyright={item.image.info}
              link={`/news/${item.slug}`}
              date={item.date}
            />)
      }
    </Root>
  );
}

export default News;
