import React from 'react';
import { Root } from './styles';

interface IContainer {
  children: JSX.Element[] | JSX.Element;
}

function Container(props: IContainer) {
  return (
    <Root>
      {props.children}
    </Root>
  );
}

export default Container;
