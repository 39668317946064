import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './styles/global';
import { theme } from './styles/theme';
import { Home, News, Search, Categories } from './pages';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path='/'  element={<Home />} />
        <Route path='/news/:id'  element={<News />} />
        <Route path='/search'  element={<Search />} />
        <Route path='/categories'  element={<Categories />} />
      </Routes>
      <GlobalStyles />
    </ThemeProvider>
  );
}

export default App;
