import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from "react-router-dom";
import { fetchTags } from '../../store/AC/tags';
import { fetchMainNews, fetchHotNews, fetchPopularNews, } from '../../store/AC/news';
import { fetchCategories } from '../../store/AC/categories';
import { IRootState, ITagsRedux, ICategoriesRedux, INewsRedux } from '../../models/state';

import { Header, Container, Footer, TagsCloud, Categories as CategoriesCopm, SideNews, PopularNews, NewsItem } from '../../components';
// import { SearchBlock, SelectedCategories } from './components';
import { Root, Title, Content, LeftContainer, RightContainer,NewsContainer } from './styles';
import { ICategories } from '../../models/categories';


export interface IState {
  categoryType: string;
  tags: string[];
  categories: string[];
}

function Categories(){
  const [state, setState] = useState<IState>({
    categoryType: 'Все',
    tags: [],
    categories: [],
  });

  const dispatch = useDispatch();
  const tags = useSelector<IRootState, ITagsRedux>((state) => state.tags);
  const categories = useSelector<IRootState, ICategoriesRedux>((state) => state.categories);
  const news = useSelector<IRootState, INewsRedux>((state) => state.news);

  
  const { search } = useLocation();
  const category = new URLSearchParams(search).get('category') || 'Все';
  
  useEffect(() => {
    document.title = 'Новости по категориям';
  }, []);

  useEffect(() => {
    if (!tags.list.length) {
      dispatch(fetchTags());
    }
    if (!categories.list.length) {
      dispatch(fetchCategories());
    }
    if(!news.hot.length){
      dispatch(fetchHotNews({ isPublished: true, type: ['hot'] }));
    }
    if(!news.popular.length){
      dispatch(fetchPopularNews({ isPublished: true, last: true, limit: 6 }));
    }
  }, [tags.list.length, categories.list.length,news.hot.length,news.popular.length, dispatch]);

  useEffect(() => {
    dispatch(fetchMainNews({ isPublished: true, categories: [category] }));
  }, [category]);

  // Tags
  const setTag = (tag: string) => {
    const index = state.tags.indexOf(tag);

    if (index === -1) {
      state.tags.push(tag);
      setState({ ...state });
    } else {
      setState({ ...state, tags: state.tags.filter(item => item !== tag) });
    }
  }

  return (<>
    <Header />
    <Root>
      <Container>
        <Title>Новости {category === "Институты" ? "Институтов" : category}</Title>
        <CategoriesCopm list={[{title:'Все'},...categories.list]}/>
        <Content>
          <LeftContainer>
            <NewsContainer>
              {news.main.map( (el, ind) => (
                <NewsItem {...el} key={el.title + ind}/>
              ))}
            </NewsContainer>
          </LeftContainer>
          <RightContainer>
            {/* <TagsCloud tagsList={tags.list} setTag={setTag} activeTags={state.tags} /> */}
            <SideNews title="Горячие новости" news={news.hot}/>
            <SideNews title="Последние новости" news={news.popular}/>
          </RightContainer>
        </Content>
      </Container>
      <PopularNews news={news.popular}/>
    </Root>
    <Footer/>
  </>)
}

export default Categories