import { NewsItem } from "../../../../components";
import { INews } from "../../../../models/news";
import { Root, Title } from "./styles";

interface IProps {
  list: INews[];
}

function NewsList(props: IProps) {
  const { list } = props;

  return (
    <Root>
      <Title>По вашему запросу найдено новостей: {list.length}</Title>
      {
        list.map(
          item => <NewsItem key={item.id} {...item} />
        )
      }
    </Root>
  );
}

export default NewsList;
