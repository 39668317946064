import styled from 'styled-components';

export const Root = styled.article`
  &>a{
    display: flex;
    text-decoration:none;
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.darkGray};
    cursor: pointer;

    &:hover>div>h1{
      color: ${({ theme }) => theme.colors.blue};
    }
  }
  &:last-child>a {
    border-bottom: none;
    padding-bottom: 0;
  }
  &:first-child>a{
    margin-top: 0;
  }
`;

export const Image = styled.img`
  display: none;

  @media ${({ theme }) => theme.media.tablet} {
    flex-shrink: 0;
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;
    object-fit: none;
  }
`;

export const DescriptionContainer = styled.div`
  @media ${({ theme }) => theme.media.tablet} {
    margin-top: 0;
    width: 100%;
    margin-left: 20px;
  }
`;

export const Title = styled.h1`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  @media ${({ theme }) => theme.media.desktop} {
    font-size: 18px;
  }
`;

export const Text = styled.p`
  margin-top: 10px;
  font-size: 12px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.black};

  & img {
    display: none;
  }

  & h1, h2 {
    display: none;
  }

  // Многоточие
  text-overflow: ellipsis;
  overflow: hidden;
  -ms-line-clamp: 3;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  display: -webkit-box;
  display: box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  box-orient: vertical;

  @media ${({ theme }) => theme.media.desktop} {
    font-size: 14px;
  }
`;

export const DateContainer = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};

  @media ${({ theme }) => theme.media.desktop} {
    font-size: 14px;
  }
`;
