import styled from "styled-components";

export const Root = styled.section`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  overflow: hidden;

  @media ${({ theme }) => theme.media.tablet} {
    padding: 20px;
  }
`;

export const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  @media ${({ theme }) => theme.media.desctop} {
    font-size: 24px;
  }
`;
