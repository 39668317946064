import { call, put } from 'redux-saga/effects';
import CategoriesApi from '../../../API/CategoriesApi';
import { ResponseGenerator, IParamsCategoriesGET } from '../../../models/fetch';
import { fetchCategoriesSuccess, fetchCategoriesFail } from '../../AC/categories';

interface IParams {
  type: string;
  payload: IParamsCategoriesGET;
}

function* fetchCategories(params: IParams) {
  try {
    const response: ResponseGenerator = yield call(CategoriesApi.fetchCategoriesList, params.payload);

    if (response.status === 200) {
      yield put(fetchCategoriesSuccess(response.data));
    }
    
    else {
      yield put(fetchCategoriesFail('Ошибка загрузки списка категорий'));
    }
  } catch(error) {
    console.log(error);
  }
}

export default fetchCategories;
