import React from 'react'
import { Root } from './styles';

interface IButton {
  size?: string;
  children: JSX.Element | string;
  color?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

function Button({ children, size = 'normal', color = 'primary', onClick }: IButton) {

  return (
    <Root size={size} color={color} onClick={onClick}>
      {children}
    </Root>
  );
}

export default Button;
