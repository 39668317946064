import { ITags } from '../../models/tags';
import { Root, Title, TagsContainer, Tag } from './styled';

interface IProps {
  activeTags: string[];
  tagsList: ITags[];
  setTag: (tag: string) => void;
}

function TagsCloud(props: IProps) {
  const { tagsList, setTag, activeTags } = props;

  return (
    <Root>
      <Title>
        Облако тегов
      </Title>

      <TagsContainer>
        {
          tagsList.map((item, i) => {
            const selected = !!activeTags.find(activeItem => activeItem === item.title );
            return <Tag key={i} onClick={() => setTag(item.title)} selected={selected}>{item.title}</Tag>
          })
        }
      </TagsContainer>
    </Root>
  );
}

export default TagsCloud;
