import { Root } from "./styles";
import iconMenuSrc from '../../images/icon_menu.svg';
import iconLoupeSrc from '../../images/icon_loupe.svg';
import iconCloseSrc from '../../images/icon_close.svg';
import iconClearSrc from '../../images/icon_clear.svg';
import iconCheckSrc from '../../images/icon_check.svg';

type Type = 'menu' | 'loupe' | 'close' | 'clear' | 'check';

interface IIcon {
  alt?: string;
  type: Type;
  width?: string;
}

function Icon(props: IIcon) {
  let { alt, type, width } = props;
  let src;

  switch(type) {
    case 'menu':  src = iconMenuSrc;  break;
    case 'loupe': src = iconLoupeSrc; break;
    case 'close': src = iconCloseSrc; break;
    case 'clear': src = iconClearSrc; break;
    case 'check': src = iconCheckSrc; break;
  }

  return (
    <Root src={src} alt={alt} width={width} />
  );
}

export default Icon;
