import { takeLatest } from 'redux-saga/effects';
import { NewsActions, TagsActions, CategoriesActions } from '../actions';
import { fetchMainNews, fetchSliderNews, fetchHotNews, fetchPopularNews, fetchThemesNews, fetchSearchNews } from './news';
import { fetchTags } from './tags';
import { fetchCategories } from './categories';

function* rootSaga() {
  yield takeLatest(NewsActions.FETCH_MAIN_NEWS, fetchMainNews);
  yield takeLatest(NewsActions.FETCH_SLIDER_NEWS, fetchSliderNews);
  yield takeLatest(NewsActions.FETCH_HOT_NEWS, fetchHotNews);
  yield takeLatest(NewsActions.FETCH_POPULAR_NEWS, fetchPopularNews);
  yield takeLatest(NewsActions.FETCH_THEMES_NEWS, fetchThemesNews);
  yield takeLatest(NewsActions.FETCH_SEARCH_NEWS, fetchSearchNews);

  yield takeLatest(TagsActions.FETCH_TAGS, fetchTags);

  yield takeLatest(CategoriesActions.FETCH_CATEGORIES, fetchCategories);
}

export default rootSaga;
