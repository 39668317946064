import { Root, ButtonContainer, Button, Point, Text } from './styles';

interface IProps {
  onChange: (value: string) => void;
  value: string;
  variants: string[];
};

function RadioButtons(props: IProps) {
  const { variants, value, onChange } = props;
  return (
    <Root>
      {
        variants.map(item => 
          <ButtonContainer key={item} onClick={() => onChange(item)}>
            <Button>
              {value === item && <Point />}
            </Button>
            <Text>{item}</Text>
          </ButtonContainer>  
        )
      }
    </Root>
  );
}

export default RadioButtons;
