import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Container from '../Container';
import Icon from '../Icon';
import Button from '../Button';
import {
  Root,
  Logo,
  ButtonMenuContainer,
  ButtonMenu,
  SearchInput,
  ButtonSearchContainer,
  Fade,
  MenuContainer,
  MenuSearchContainer,
  MenuButtonSearchContainer,
  MenuList,
  MenuItemBig,
  MenuItem,
} from './styles';
import logoSrc from '../../images/logo-small.svg';

function Header() {
  const [isOpen, setOpen] = useState(false);

  return (
    <Root>
      <Container>
        <Link to='/'>
          <Logo src={logoSrc} />
        </Link>
        <ButtonSearchContainer>
          <Link to="/search">
            <Button color='transparent'>Расширенный поиск</Button>
          </Link>
        </ButtonSearchContainer>
        {/* <ButtonMenuContainer>
          <ButtonMenu onClick={() => setOpen(prev => !prev)}>
            {
              isOpen ?
                <Icon type="close" width="40px" />
                : <Icon type="menu" width="40px" /> }
          </ButtonMenu>
        </ButtonMenuContainer> */}
      </Container>

      {/* MENU */}
      {
        // isOpen &&
        //   <>
        //     <Fade />
        //     <MenuContainer>
        //       <MenuSearchContainer>
        //         <SearchInput
        //           placeholder="Быстрый поиск"
        //           type="text"
        //         />
        //         <Icon type='loupe' width="16px" />
        //       </MenuSearchContainer>
              
        //       <MenuButtonSearchContainer>
        //         <Button color='transparent'>Расширенный поиск</Button>
        //       </MenuButtonSearchContainer>
        //       <div>
        //         <MenuList>
        //           <MenuItemBig>
        //             <Link to="/link">МИНОБРНАУКИ</Link>
        //           </MenuItemBig>
        //           <MenuItemBig>
        //             <Link to="/link">РАН</Link>
        //           </MenuItemBig>
        //           <MenuItemBig>
        //             <Link to="/link">СО РАН</Link>
        //           </MenuItemBig>
        //           <MenuItemBig>
        //             <Link to="/link">ННЦ СО РАН</Link>
        //           </MenuItemBig>
        //           <MenuItemBig>
        //             <Link to="/link">БНЦ СО РАН</Link>
        //           </MenuItemBig>
        //           <MenuItemBig>
        //             <Link to="/link">ИНЦ СО РАН</Link>
        //           </MenuItemBig>
        //           <MenuItemBig>
        //             <Link to="/link">ОНЦ СО РАН</Link>
        //           </MenuItemBig>
        //           <MenuItemBig>
        //             <Link to="/link">ТНЦ СО РАН</Link>
        //           </MenuItemBig>
        //           <MenuItemBig>
        //             <Link to="/link">ФИЦ КНЦ СО РАН</Link>
        //           </MenuItemBig>
        //           <MenuItemBig>
        //             <Link to="/link">ФИЦ ТюмНЦ СО РАН</Link>
        //           </MenuItemBig>
        //           <MenuItemBig>
        //             <Link to="/link">ФИЦ УУХ СО РАН</Link>
        //           </MenuItemBig>
        //           <MenuItemBig>
        //             <Link to="/link">ФИЦ ЯНЦ СО РАН</Link>
        //           </MenuItemBig>
        //         </MenuList>
        //       </div>
        //       <MenuList>
        //         <MenuItem>
        //           <Link to="/link">Институты</Link>
        //         </MenuItem>
        //         <MenuItem>
        //           <Link to="/link">Конкурсы и гранты</Link>
        //         </MenuItem>
        //         <MenuItem>
        //           <Link to="/link">Итоги</Link>
        //         </MenuItem>
        //         <MenuItem>
        //           <Link to="/link">Конференции и семинары</Link>
        //         </MenuItem>
        //         <MenuItem>
        //           <Link to="/link">Образование. Университеты</Link>
        //         </MenuItem>
        //         <MenuItem>
        //           <Link to="/link">Анонсы</Link>
        //         </MenuItem>
        //         <MenuItem>
        //           <Link to="/link">Дайджест</Link>
        //         </MenuItem>
        //       </MenuList>
        //     </MenuContainer>
        //   </>
      }
    </Root>
  );
}

export default Header;
