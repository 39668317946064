import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Root } from './styles';


interface IProps {
  children: any;
}

function CarouselComponent(props: IProps) {
  return (
    <Root>
      <Carousel
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={5000}
      >
        {props.children}
      </Carousel>
    </Root>
  );
}

export default CarouselComponent;
