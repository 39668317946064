import styled from 'styled-components';

export const Root = styled.main`

  margin-top: 20px;

  @media ${({ theme }) => theme.media.laptop} {
    margin-top: 40px;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  @media ${({ theme }) => theme.media.tablet} {
    font-size: 34px;
  }

  @media ${({ theme }) => theme.media.laptop} {
    font-size: 44px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-top: 20px;

  @media ${({ theme }) => theme.media.laptop} {
    grid-template-columns: 2fr 1fr;
  }

  @media ${({ theme }) => theme.media.desctop} {
    grid-template-columns: 1fr 460px;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-gap: 20px;
  align-self: start;
`;

export const LeftContainer = styled(GridContainer)``;

export const RightContainer = styled(GridContainer)`
  @media ${({ theme }) => theme.media.tablet} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${({ theme }) => theme.media.laptop} {
    grid-template-columns: 1fr;
  }
`;

export const ThemesNewsContainer : any = styled.div`
  margin-top: 80px;

  @media ${({ theme }) => theme.media.tablet} {
    margin-top: 100px;
  }

  @media ${({ theme }) => theme.media.laptop} {
    margin-top: 120px;
  }

  @media ${({ theme }) => theme.media.desctop} {
    margin-top: 140px;
  }
`;

export const NewsContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 20px;
`