import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMainNews, fetchSliderNews, fetchHotNews, fetchPopularNews, fetchThemesNews } from '../../store/AC/news';
import { fetchTags } from '../../store/AC/tags';
import { fetchCategories } from '../../store/AC/categories';
import { Header, Container, Categories, SideNews, PopularNews, ThemesNews, Footer } from '../../components';
import { Carousel, News } from './components';
import { Root, Title, Content, RightContainer, LeftContainer, ThemesNewsContainer } from './styles';
import { IRootState, INewsRedux, ITagsRedux, ICategoriesRedux } from '../../models/state';
import { IThemeTags } from '../../models/tags';

function Home() {
  const dispatch = useDispatch();
  const news = useSelector<IRootState, INewsRedux>((state) => state.news);
  const tags = useSelector<IRootState, ITagsRedux>((state) => state.tags);
  const categories = useSelector<IRootState, ICategoriesRedux>((state) => state.categories);

  useEffect(() => {
    document.title = 'Новости Сибирской науки';
  }, []);

  useEffect(() => {
    dispatch(fetchMainNews({ isPublished: true, last: true, limit: 6 }));
    dispatch(fetchSliderNews());
    dispatch(fetchHotNews({ isPublished: true, last: true, type: ['hot'] }));
    dispatch(fetchPopularNews());
    dispatch(fetchThemesNews({ isPublished: true, last: true, limit: 6 }));
    dispatch(fetchTags());
    dispatch(fetchCategories());
  }, [dispatch]);

  console.log('news', news);


  const [themesTags, setThemesTags] = useState<IThemeTags[]>([]);

  // Добавил теги когда они загрузились
  if (themesTags.length === 0 && tags.list.length !== 0) {
    setThemesTags(tags.list.map(item => ({ ...item, isSelected: false })));
  }

  const handleTagsClick = (tags: IThemeTags[]): void => {
    setThemesTags(tags);

    const activeTags = tags
      .filter(item => item.isSelected)
      .map(item => item.title);

    dispatch(fetchThemesNews({ isPublished: true, tags: activeTags, limit: activeTags.length ? 20 : 6 }));
  }

  const categoriesList = [{ title: 'Все' }, ...categories.list];

  return (
    <>
      <Header />
      <Root>
        <Container>
          <Title>Новости науки</Title>
          <Categories list={categoriesList} />
          <Content>
            <LeftContainer>
              <Carousel news={news.slider} />
              <News news={news.main} />
            </LeftContainer>
            <RightContainer>
              <SideNews title="Горячие новости" news={news.hot} />
            </RightContainer>
          </Content>
        </Container>
        <PopularNews news={news.popular} />
        <ThemesNewsContainer>
          <ThemesNews tags={themesTags} news={news.themes} setTags={handleTagsClick} />
        </ThemesNewsContainer>

        <Footer />
      </Root>
    </>
  );
}

export default Home;
