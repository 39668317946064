import styled from 'styled-components';

export const Root = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

export const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  // height: 500px;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit:cover;
  // height: 100%;
`;