import styled from 'styled-components';

interface IType {
  isMain: boolean;
}

export const Root = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.darkGray};
  /* border-bottom: 1px solid ${({ theme }) => theme.colors.darkGray}; */

  @media ${({ theme }) => theme.media.tablet} {
    padding-top: 20px;
    margin-top: 40px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.span`
  font-size: 16px;
  font-weight: 700;
  @media ${({ theme }) => theme.media.desctop} {
    font-size: 18px;
  }
`
export const ShowBtnContainer = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  @media ${({ theme }) => theme.media.desctop} {
    font-size: 14px;
  }
`

export const ShowBtn = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding-right: 30px;
  font-weight: 700;
  margin-left: 10px;
  background-image: url('/img/icon/arrow_pink.svg');
  background-repeat: no-repeat;
  background-position: center right;
  cursor: pointer;
  @media ${({ theme }) => theme.media.desctop} {
    font-size: 14px;
  }
`
export const Body = styled.div`
  margin-top: 10px;
  @media ${({ theme }) => theme.media.tablet} {
    
    margin-top: 20px;
  }
`

export const Source = styled.div`
  padding-left: 12px;
  margin-top: 10px;

  &:first-child{
    margin-top: 0;
  }

  & > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
  }
  
`

export const SourceTitle = styled.div<IType>`
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 10px;
  position: relative;
  padding-right: 20px;
  color: ${({ theme }) => theme.colors.blue};
  cursor:pointer;
  &::before{
    content:"";
    display: block;
    position: absolute;
    left:-12px;
    top:0;
    width: 2px;
    height: 100%;
    border-radius: 5px;
    background: ${({ theme,isMain }) =>  theme.colors[isMain ? 'blue3' : 'gray3'] };
  }
`

export const SourceDate = styled.div`
  font-size: 12px;
`