import { TagsActions } from '../actions';
import { ITagsRedux } from '../../models/state';
import { IActionTags } from '../../models/actions';

const initialState: ITagsRedux = {
  list: [],
  error: null,
};

function tagsReducer(state = initialState, action: IActionTags) {
  switch(action.type) {
    case TagsActions.FETCH_TAGS_SUCCESS:
      return { ...state, list: action.payload };
    case TagsActions.FETCH_TAGS_ERROR:
      return { ...state, error: action.payload };
    default: return state;
  }
}

export default tagsReducer;
