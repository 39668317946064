import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.media.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;

  @media ${({ theme }) => theme.media.tablet} {
    margin-top: 0;
    margin-left: 80px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.blue};
  border-radius: 50%;
`;

export const Point = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: 50%;
`;

export const Text = styled.span`
  margin-left: 10px;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};
`;
