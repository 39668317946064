import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-self: start;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
`;

export const Title = styled.h2`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.black};
`;

export const LinkContainer = styled.span`
  align-self: flex-end;
  margin-top: 10px;

  & > a {
    font-size: 14px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.blue};
  }
`;
