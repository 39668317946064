import axios from '../axios';
import { IParamsCategoriesGET } from '../models/fetch';

class CategoriesApi {
  static fetchCategoriesList(query: IParamsCategoriesGET = {}) {
    return axios.get('/categories/list', { params: query })
      .then(response => response)
      .catch(({ response }) => ({ ...response }));
  }
};

export default CategoriesApi;
