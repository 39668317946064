import { ISource } from '../../../../models/sources';
import {
  Root, Header, Title, Body,
  Source, SourceTitle, SourceDate
} from './styles';


interface IProps {
  sources: ISource[];
  mainSource?: ISource;
}

function Sources(props: IProps) {
  const { sources, mainSource } = props;
  // Обрезать слишком длинные title
  // date будет числом сделать функции
  // Сделать вэбсайт полноценной ссылкой и из него вырезать название сайта
  // testArr заменить на валидные данные


  const getDate = (dateSource: number) => {
    const date = new Date(dateSource);
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
  };
  const getTime = (dateSource: number) => {
    const date = new Date(dateSource);
    return `${date.getHours()}:${date.getMinutes()}`;
  };

  return (
    <Root>
      <Header>
        <Title>Источники</Title>
      </Header>
      <Body>
        {
          mainSource ?
            <Source>
              <a href={mainSource.link} target="_blank" rel="noreferrer">
                <SourceTitle isMain>
                  {mainSource.title}
                </SourceTitle>
                <SourceDate>
                  — {mainSource.mass_media}, {getDate(mainSource.date)} {getTime(mainSource.date)}
                </SourceDate>
              </a>
            </Source>
            : null
        }
        {
          sources.map(
            (item, i) =>
              <Source key={i}>
                <a href={item.link} target="_blank" rel="noreferrer">
                  <SourceTitle isMain={false}>
                    {item.title}
                  </SourceTitle>
                  <SourceDate>
                    — {item.mass_media}, {getDate(item.date)} {getTime(item.date)}
                  </SourceDate>
                </a>
              </Source>
          )
        }
        {/* sources.splice(0,lengthShow).map( (el, ind )=> (<Source key={el.title}>
        <Link to={el.mass_media} target="_blank">
          <SourceTitle isMain={ ind === 0 }>
            {el.title}
          </SourceTitle>
        </Link>
        <SourceDate>
          — {el.mass_media}, {el.date}
        </SourceDate>
      </Source>))
       */}
      </Body>
    </Root>)
}

export default Sources