import styled from 'styled-components';

export const Root = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);

  @media ${({ theme }) => theme.media.tablet} {
    padding: 20px;
  }
`;

export const Title = styled.h2`
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  &:first-child {
    margin-top: 0;
  }

  @media ${({ theme }) => theme.media.laptop} {
    font-size: 24px;
  }
`;

export const FilterContainer = styled.div`
  margin-top: 20px;
`;

export const SearchContainer = styled(FilterContainer)`
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.media.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  border: 2px solid ${({ theme }) => theme.colors.darkGray};
  border-radius: 10px;

  @media ${({ theme }) => theme.media.tablet} {
    width: 100%;
  }
`;

export const Input = styled.input`
  padding: 0;
  width: 100%;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.black};
  border: none;
`;

export const ClearButton = styled.button`
  margin-left: 20px;
  width: 17px;
  height: 17px;
  background: transparent;
  border: none;
  cursor: pointer;

  & > img {
    width: 17px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 10px;

  & > button {
    width: 100%;
  }

  @media ${({ theme }) => theme.media.tablet} {
    margin-top: 0;
    margin-left: 20px;
  }
`;
