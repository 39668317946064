import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  @font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: bold;
  }

  @font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato-BoldItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: bold;
  }

  @font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight: normal;
  }

  body {
    margin: 0;
    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f5f5f5;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  #root{
    padding-top: 80px;
    display:flex;
    flex-direction: column;
    min-height: 100vh;
    &>main{
      flex: 1 0 auto;
    }
    &>header{
      flex: 0 0 auto;
    }
    &>footer{
      flex: 0 0 auto;
    }
  }

  * {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
  }
`;