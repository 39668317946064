import styled from 'styled-components';

export const Root = styled.header`
  position: fixed;
  top: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  background-color: ${({ theme }) => theme.colors.black};

  @media ${({ theme }) => theme.media.tablet} {
    height: 96px;
  }

  // <Container />
  & > div {
    display: flex;
    align-items: center;
  }
`;

export const Logo = styled.img`
  width: 100px;

  @media ${({ theme }) => theme.media.tablet} {
    width: 132px;
  }
`;

export const ButtonMenuContainer = styled.div`
  margin-left: auto;

  @media ${({ theme }) => theme.media.tablet} {
    margin-left: 50px;
  }
`;

export const ButtonMenu = styled.button`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
`;

export const SearchContainer = styled.div`
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  padding-bottom: 5px;
  width: 240px;
  border-bottom: 1px solid #fff;

  @media ${({ theme }) => theme.media.tablet} {
    display: flex;
  }

  @media ${({ theme }) => theme.media.desctop} {
    margin-left: auto;
  }
`;

export const SearchInput = styled.input`
  width: 210px;
  font-size: 14px;
  color: #fff;
  background-color: transparent;

  &::placeholder {
    color: #fff;
  }
`;

export const ButtonSearchContainer = styled.div`
  display: block;
  margin-left: auto;

`;

export const Fade = styled.section`
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, .2);
  z-index: 9;

  @media ${({ theme }) => theme.media.tablet} {
    top: 96px;
  }
`;

export const MenuContainer = styled.section`
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  overflow-y: auto;
  z-index: 10;


  @media ${({ theme }) => theme.media.tablet} {
    top: 96px;
    padding: 40px;
  }

  @media ${({ theme }) => theme.media.laptop} {
    left: inherit;
    bottom: inherit;
    padding: 40px 120px 40px 40px;
  }
`;

export const MenuSearchContainer = styled(SearchContainer)`
  display: flex;
  margin-left: 0;

  @media ${({ theme }) => theme.media.tablet} {
    display: none;
  }
`;

export const MenuButtonSearchContainer = styled(ButtonSearchContainer)`
  display: block;
  margin-left: 0;
  margin-top: 20px;

  @media ${({ theme }) => theme.media.tablet} {
    display: none;
  }
`;

export const MenuList = styled.ul`
  margin-top: 20px;
  list-style: none;

  @media ${({ theme }) => theme.media.tablet} {
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  }

`;

export const MenuItemBig = styled.li`
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }

  & > a {
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
    transition-duration: .3s;

    &:hover {
      color: ${({ theme }) => theme.colors.pink};
    }

    @media ${({ theme }) => theme.media.tablet} {
      font-size: 24px;
    }

    @media ${({ theme }) => theme.media.laptop} {
      font-size: 18px;
    }

    @media ${({ theme }) => theme.media.desctop} {
      font-size: 24px;
    }
  }
`;

export const MenuItem = styled(MenuItemBig)`
  & > a {
    font-size: 14px;
    font-weight: normal;
    opacity: .6;

    &:hover {
      color: ${({ theme }) => theme.colors.white};
      opacity: 1;
    }

    @media ${({ theme }) => theme.media.tablet} {
      font-size: 16px;
    }

    @media ${({ theme }) => theme.media.laptop} {
      font-size: 14px;
    }

    @media ${({ theme }) => theme.media.desctop} {
      font-size: 18px;
    }
  }
`;
