import { NewsActions } from '../actions';
import { INewsRedux } from '../../models/state';
import { IActionNews } from '../../models/actions';


const initialState: INewsRedux = {
  main: [],
  slider: [],
  hot: [],
  popular: [],
  themes: [],
  search: [],
  error: null,
};

function newsReducer(state = initialState, action: IActionNews) {
  switch (action.type) {
    case NewsActions.FETCH_MAIN_NEWS_SUCCESS:
      return { ...state, main: action.payload };
    case NewsActions.FETCH_SLIDER_NEWS_SUCCESS:
      return { ...state, slider: action.payload };
    case NewsActions.FETCH_HOT_NEWS_SUCCESS:
      return { ...state, hot: action.payload };
    case NewsActions.FETCH_POPULAR_NEWS_SUCCESS:
      return { ...state, popular: action.payload };
    case NewsActions.FETCH_THEMES_NEWS_SUCCESS:
      return { ...state, themes: action.payload };
    case NewsActions.FETCH_SEARCH_NEWS_SUCCESS:
      return { ...state, search: action.payload };
    default: return state;
  }
}

export default newsReducer;
