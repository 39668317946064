import styled from 'styled-components';

export const Root = styled.footer`
  margin-top: 80px;
  padding: 20px 0;
  background-color: ${({ theme }) => theme.colors.black};

  @media ${({ theme }) => theme.media.tablet} {
    margin-top: 100px;
  }

  @media ${({ theme }) => theme.media.tablet} {
    margin-top: 120px;
  }

  @media ${({ theme }) => theme.media.tablet} {
    margin-top: 140px;
  }

  // Container
  & > div {
    display: flex;
    flex-direction: column;
  }
`;

export const Image = styled.img`
  align-self: center;
  width: 200px;
`;

export const LinksContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 20px;
  width: 160px;
`;

export const Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
`;

export const HR = styled.hr`
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Organization = styled.p`
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.white};
`;
