import { useCallback, useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { IRootState, INewsRedux, ICategoriesRedux } from '../../models/state';
import { fetchHotNews, fetchPopularNews } from '../../store/AC/news';
import { fetchCategories } from '../../store/AC/categories';
import { INews } from '../../models/news';
import NewsApi from '../../API/NewsApi';

import { Header, Container, Categories, SideNews, PopularNews, Footer } from '../../components';
import { Sources } from './Components'
import {
  Root, ContainerNews, Title, Image, DataNews, DataNewsList, DataItem,
  DataHr, Content, LeftContent, RightContent, NewsContent, NewsTitle, NewsText,
  FooterNews, TagsList, TagItem,
} from './styles';

interface IState {
  isLoading: boolean | null;
  news: INews | null;
  error: string | null;
}

function News() {
  let { pathname } = useLocation()
  const slug = pathname.split('/').pop();

  const [currentNews, setCurrentNews] = useState<IState>({
    isLoading: null,
    news: null,
    error: null,
  });
  const [currentSlug, setSlug] = useState(slug);

  const fetchNewsCallback = useCallback(fetchNews, [currentNews, slug]);

  const dispatch = useDispatch();
  const news = useSelector<IRootState, INewsRedux>((state) => state.news);
  const categories = useSelector<IRootState, ICategoriesRedux>((state) => state.categories);

  useEffect(() => {
    document.title = currentNews.news?.title || 'Новосити Сибирской науки';
  }, [currentNews.news]);

  useEffect(() => {
    dispatch(fetchPopularNews());
    dispatch(fetchHotNews({ isPublished: true, limit: 5, type: ['hot'] }));
    dispatch(fetchCategories());
  }, [dispatch]);

  // Загрузка новости
  useEffect(() => {
    if (currentNews.isLoading === null) {
      fetchNewsCallback();
    }
  }, [fetchNewsCallback, currentNews.isLoading]);

  useEffect(() => {
    if (currentNews.news !== null && currentSlug !== slug) {
      setSlug(slug);
      fetchNewsCallback();
    }
  }, [slug, currentSlug, currentNews.news, fetchNewsCallback]);

  async function fetchNews() {
    setCurrentNews({ ...currentNews, isLoading: true });
    if (typeof slug === 'string') {
      const result = await NewsApi.fetchOneNews(slug);

      if (result.status === 200) {
        setCurrentNews({ news: result.data, isLoading: false, error: null });
      }
    }
  }

  const getDate = (date: any) => {
    const newDate: Date = new Date(date)
    let day = newDate.getDate()
    let month = newDate.getMonth()
    let year = newDate.getFullYear()
    return `${day}.${month + 1}.${year}`
  }
  const getTime = (date: any) => {
    const newDate: Date = new Date(date)
    let hours = newDate.getHours()
    let minuts = newDate.getMinutes()
    return `${hours}:${minuts}`
  }

  const categoriesList = [{ title: 'Все' }, ...categories.list];

  const getUrl = (url: string) => `${process.env.REACT_APP_ADMIN_URL}${url}`;

  return (
    <>
      <Header />
      <Root>
        <Container>
          <Title>Новости СО РАН</Title>
          <Categories list={categoriesList} />
          <Content>
            <LeftContent>
              <ContainerNews>
                {
                  currentNews.news &&
                  <>
                    <Image src={getUrl(currentNews.news.image.path)} />
                    <DataNews>
                      <DataNewsList>
                        {
                          currentNews.news.tags?.map((item, i) =>
                            <DataItem key={'tags_news' + i}>
                              {item.title}
                            </DataItem>
                          )
                        }
                      </DataNewsList>
                      <DataHr />
                      <DataNewsList>
                        <DataItem>{getDate(currentNews.news.date)}</DataItem>
                        <DataItem>{getTime(currentNews.news.date)}</DataItem>
                      </DataNewsList>
                    </DataNews>
                    <NewsContent>
                      <NewsTitle>{currentNews.news.title}</NewsTitle>
                      <NewsText dangerouslySetInnerHTML={{ __html: currentNews.news.text }} />
                    </NewsContent>
                    <FooterNews>
                      <TagsList>
                        {currentNews.news.tags.map((el, ind) => el.link ?
                          <TagItem target="_blank" key={'tag' + ind} href={el.link} >
                            {el.title}
                          </TagItem>
                          :
                          <TagItem key={'tag' + ind} >
                            {el.title}
                          </TagItem>
                        )}
                      </TagsList>
                      {
                        currentNews.news.sources.length ?
                          <Sources sources={currentNews.news.sources} mainSource={currentNews.news.mainSource} />
                          : null
                      }
                    </FooterNews>
                  </>
                }
              </ContainerNews>
            </LeftContent>

            <RightContent>
              <SideNews title="Горячие новости" linkMore="/news" news={news.hot} />
            </RightContent>
          </Content>
        </Container>
        <PopularNews news={news.popular} />
        <Footer />
      </Root>
    </>
  );
}

export default News;