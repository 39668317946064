import styled from 'styled-components';

export const Root = styled.main`
  margin-top: 20px;

  @media ${({ theme }) => theme.media.laptop} {
    margin-top: 40px;
  }
`;

export const ContainerNews = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding:10px;
  
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06);
  @media ${({ theme }) => theme.media.tablet} {
    padding:20px;
  }
`

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};

  @media ${({ theme }) => theme.media.tablet} {
    font-size: 34px;
  }

  @media ${({ theme }) => theme.media.laptop} {
    font-size: 44px;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;
export const LeftContent = styled.div`
  flex-grow: 1;
`;
export const RightContent = styled.div`
  display: none;
  flex-shrink: 0;
  @media ${({ theme }) => theme.media.laptop} {
    display: block;
    width: 360px;
  }
  @media ${({ theme }) => theme.media.desctop} {
    width: 480px;
  }
`;

export const Image = styled.img`
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
`

export const DataNews = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  @media ${({ theme }) => theme.media.tablet} {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
`
export const DataNewsList = styled.div`
  display:flex;
  justify-content: center;
`
export const DataItem = styled.span`
  position: relative;
  padding-right: 25px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.darkGray};

  &::after{
    display:block;
    position: absolute;
    content:"";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.darkGray};
    top:50%;
    right: 10px;
    transform: translateY(-50%);
  }

  &:last-child{
    padding-right: 0;
    &::after{
    display:none;
  }}


  @media ${({ theme }) => theme.media.desctop} {
    font-size: 14px;
  }
`
export const DataHr = styled.div`
  width: 181px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.darkGray};
  margin: 10px 0;

  @media ${({ theme }) => theme.media.tablet} {
    flex-grow: 1;
    margin: 0 20px;
  }
`

export const NewsContent = styled.div`
  margin-top: 20px;
`
export const NewsTitle = styled.h2`
  font-size: 18px;
  font-weight: bold;

  @media ${({ theme }) => theme.media.tablet} {
    font-size: 24px;
  }

  @media ${({ theme }) => theme.media.desctop} {
    font-size: 34px;
  }
`
export const NewsText = styled.div`
  margin-top: 10px;
  & p{
    font-size: 12px;
    margin-top: 20px;

    &:last-child{
      margin-bottom:0;
    }
  }
  & ul{  
    list-style: none;
    padding-left: 15px;
    &>li{
      position: relative;
      margin-top: 10px;
      font-size: 13px;
      line-height: 19.6px;
      &:first-child{
        margin-top: 0;
      }
      &::before{
        position:absolute;
        display: block;
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.pink};
        top:50%;
        left: -15px;
        transform: translateY(-50%);
      }
    }
  }

  & img {
    margin-top: 20px;
    width: 100%;
  }

  & a{
    font-size: 13px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.blue};
    line-height: 19.6px;
    margin-right: 2px;
  }

  @media ${({ theme }) => theme.media.tablet} {
    margin-top:20px;
  }

  @media ${({ theme }) => theme.media.desctop} {
    & p{
      font-size: 14px;
    }

    
  }
`
export const FooterNews = styled.div`
  margin-top: 40px;

`
export const TagsList = styled.div`
  display: flex;
  gap:10px;
  flex-wrap:wrap;
`
export const TagItem = styled.a`
  display: block;
  padding: 10px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.lightBlue};
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  color: black;
  text-decoration: none;
  @media ${({ theme }) => theme.media.desctop} {
    font-size: 16px;
    line-height: 19px;
  }
`
// "id": 1,
// "title": "Загадочные марсианские камни рассказали о давней вулканической катастрофе",
// "text": "В Отделении ГПНТБ СО РАН 13 мая была торжественно открыта Мемориальная библиотека академика Льва Васильевича Овсянникова. В неё вошли личная коллекция книг, журналов, оттисков  статей и архивных материалов ученого, его личные вещи. приобретённые им в течение его жизни и подаренные ему учениками и коллегами. Открытие Мемориальной библиотеки приурочено к  знаковому событию: 18 мая 2022 г. Сибирское отделение РАН отметит 65-летие.",
// "image": "/files/images/news2.jpg",
// "date": {
//     "$numberLong": "1669758673135"
// },
// "slug": "slag_dlya_novosti_1",
// "tags": [],
// "type": ["slider"],
// "isPublished": true