import styled from 'styled-components';

export const Root = styled.div`
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkGray};

  & button {
    margin-left: 10px;
    white-space: nowrap;
  }
  
  // кнопка ГОРЯЧИЕ НОВОСТИ
  & > button {
    display: none;
  }

  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  @media ${({ theme }) => theme.media.tablet} {
    display: flex;
    justify-content: space-between;

    .react-horizontal-scrolling-menu--wrapper {
      width: 580px;
    }

    // кнопка ГОРЯЧИЕ НОВОСТИ
    & > button {
      display: inline-block;
    }
  }

  @media ${({ theme }) => theme.media.laptop} {
    display: flex;
    justify-content: space-between;

    .react-horizontal-scrolling-menu--wrapper {
      width: 810px;
    }

    // кнопка ГОРЯЧИЕ НОВОСТИ
    & > button {
      display: inline-block;
    }
  }
`;