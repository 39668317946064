import styled from 'styled-components';

export const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-start: 2;

  & > article {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }

  @media ${({ theme }) => theme.media.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    & > article {
      margin-top: 0;
    }
  }
`;