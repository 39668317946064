import { useState } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { ICategories } from "../../../../models/categories";
import { useDrag } from '../../../../hooks';

import { Button, Icon } from '../../../../components';
import { Root, ButtonText } from "./styles";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

interface IProps {
  list: ICategories[];
  selectCategory: (category: ICategories) => void;
}

function SelectedCategories(props: IProps) {
  const { selectCategory, list } = props;
  const [categories, setCategories] = useState<string[]>(['Все']);

  // Drag for ScrollMenu
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag = ({ scrollContainer }: scrollVisibilityApiType) => (
    ev: React.MouseEvent
  ) => dragMove(ev, (posDiff) => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollLeft += posDiff;
    }
  });
  

  const handleItemClick = (category: ICategories) => () => {
    if (dragging) {
      return false;
    }

    const index = categories.indexOf(category.title);

    if (index === -1) {
      setCategories([...categories, category.title]);
    }
    else {
      setCategories(categories.filter(item => item !== category.title));
    }

    selectCategory(category);
  }

  const getSelected = (category: ICategories): boolean => {
    const index = categories.indexOf(category.title);

    if (index === -1) {
      return false;
    }
    return true;
  };

  return (
    <Root>
    <ScrollMenu
      onMouseDown={() => dragStart}
      onMouseUp={() => dragStop}
      onMouseMove={handleDrag}
    >
      {
        list.map(
          (item, i) =>
            <Button
              key={i}
              color={getSelected(item) ? 'blue' : 'default'}
              onClick={handleItemClick(item)}
            >
              <ButtonText>
                {getSelected(item) && <Icon type="check" width="13px" />}
                <span>{item.title}</span>
              </ButtonText>
            </Button>
        )
      }
    </ScrollMenu>
    </Root>
  )
}

export default SelectedCategories;
