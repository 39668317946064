import styled from 'styled-components';

interface IRoot {
  size: string;
  color: string;
}

export const Root = styled.button<IRoot>`
  padding: ${({ size }) => size === 'normal' ? '10px': '18px 60px'};
  font-size: ${({ size }) => size === 'normal' ? '12px': '18px'};
  color: ${
    ({ color, theme }) =>
      (color === 'primary' && theme.colors.black) ||
      (color === 'default' && theme.colors.black) ||
      (color === 'light-blue' && theme.colors.black) ||
      theme.colors.white
    };
  background-color: ${
    ({ color, theme }) =>
      (color === 'primary' && theme.colors.lightBlue) ||
      (color === 'blue' && theme.colors.blue) ||
      (color === 'pink' && theme.colors.pink) ||
      (color === 'light-blue' && theme.colors.lightBlue) ||
      (color === 'transparent' && 'rgba(0, 0, 0, 0)') ||
      (color === 'default' && 'rgba(0, 0, 0, 0)')
  };
  border: ${
    ({ color, theme }) =>
      (color === 'transparent' && `1px solid ${theme.colors.white}`)
      || 'none'
    };
  border-radius: 10px;
  cursor: pointer;

  ${({ color }) => color !== 'default' ? 'font-weight: bold;' : ''} 

  @media ${({ theme }) => theme.media.laptop} {
    font-size: ${({ size }) => size === 'normal' ? '14px': '18px'};
  }
`;
