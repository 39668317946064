import axios from '../axios'
import { IParamsNewsQueryGET } from '../models/fetch';

class NewsApi {
  static fetchTags(query: IParamsNewsQueryGET = {}) {
    return axios.get('/tags/list', { params: query })
      .then(response => response)
      .catch(({ response }) => ({ ...response }));
  }
};

export default NewsApi;
