import React, { useRef } from 'react';
import { IState } from '../../index';

import { Icon, Button, RadioButtons } from '../../../../components';
import {
  Root, Title, SearchContainer, InputContainer,
  Input, ClearButton, ButtonContainer, FilterContainer,
} from './styles';

interface IProps {
  setState: (state: IState) => void;
  state: IState;
  search: () => void;
}

function SearchBlock(props: IProps) {
  const { setState, state, search } = props;
  // Input
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeInput = (event: React.FormEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const clearField =  () => {
    setState({ ...state, search: '' });
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }

  // Category
  const changeRadioButton = (field: string) => (value: string) => {
    setState({ ...state, [field]: value });
  }

  const categoryVariants = ['Все', 'В выбранных категиориях'];

  return (
    <Root>
      <Title>Поиск</Title>
      <SearchContainer>
        <InputContainer>
          <Input
            name="search"
            value={state.search}
            onChange={handleChangeInput}
            ref={inputRef}
            placeholder="Поиск новостей..."
          />
          <ClearButton onClick={clearField}>
            <Icon type="clear" />
          </ClearButton>
        </InputContainer>

        <ButtonContainer>
          <Button color="blue" size="big" onClick={() => search()}>
            Искать
          </Button>
        </ButtonContainer>
      </SearchContainer>

      <Title>Область поиска</Title>
      <FilterContainer>
        <RadioButtons
          onChange={changeRadioButton('categoryType')}
          value={state.categoryType}
          variants={categoryVariants}
        />
      </FilterContainer>
    </Root>
  );
}

export default SearchBlock;
