export const theme = {
  colors: {
    white: '#FFF',
    black: '#181819',
    lightGray: '#CCD7EC',
    gray: '#E5E5E5',
    gray3: '#DBE7FF',
    darkGray: '#788CA7',
    pink: '#F25894',
    lightBlue: '#EAF2FC',
    blue: '#0092E3',
    blue3: '#3F5DD1',
    darkBlue: '#022567',
  },

  media: {
    tablet: '(min-width: 804px)',
    laptop: '(min-width: 1170px)',
    desctop: '(min-width: 1530px)',
  },
};