import { CategoriesActions } from '../actions';
import { ICategoriesRedux } from '../../models/state';
import { IActionCategories } from '../../models/actions';

const initialState: ICategoriesRedux = {
  list: [],
  error: null,
};

function tagsReducer(state = initialState, action: IActionCategories) {
  switch(action.type) {
    case CategoriesActions.FETCH_CATEGORIES_SUCCESS:
      return { ...state, list: action.payload };
    case CategoriesActions.FETCH_CATEGORIES_ERROR:
      return { ...state, error: action.payload };
    default: return state;
  }
}

export default tagsReducer;
