import styled from 'styled-components';
interface IArrow {
  type: string;
}

export const Root = styled.section`
  padding: 20px 0;
  margin-top: 80px;
  background-color: ${({ theme }) => theme.colors.black};

  @media ${({ theme }) => theme.media.tablet} {
    margin-top: 100px;
    padding: 40px 0;
  }

  @media ${({ theme }) => theme.media.laptop} {
    margin-top: 120px;
  }

  @media ${({ theme }) => theme.media.desctop} {
    margin-top: 140px;
  }
`;

export const Title = styled.div`
  font-family: 'Lato';
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.white};

  @media ${({ theme }) => theme.media.tablet} {
    font-size: 34px;
  }

  @media ${({ theme }) => theme.media.laptop} {
    font-size: 44px;
  }
`;

export const ScrollMenuContainer = styled.div`
  margin-top: 20px;

  @media ${({ theme }) => theme.media.desctop} {
    margin-top: 40px;
  }

  .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .react-horizontal-scrolling-menu--item  {
    margin-left: 20px;
  }

  .react-horizontal-scrolling-menu--item:first-child  {
    margin-left: 0;
  }
`;

export const Card = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 270px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 10px;

  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  @media ${({ theme }) => theme.media.desctop} {
    width: 355px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit:cover;
  border-radius: 10px;
  pointer-events: none;
`;

export const TitleNews = styled.h1`
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.white};

  // Многоточие
  text-overflow: ellipsis;
  overflow: hidden;
  -ms-line-clamp: 2;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  display: -webkit-box;
  display: box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  box-orient: vertical;

  @media ${({ theme }) => theme.media.desctop} {
    font-size: 18px;
  }
`;

export const TextNews = styled.p`
  margin: 0;
  margin-top: 10px;
  font-size: 12px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.white};

  
  text-overflow: ellipsis;
  overflow: hidden;
  -ms-line-clamp: 5;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  display: -webkit-box;
  display: box;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  box-orient: vertical;

  @media ${({ theme }) => theme.media.desctop} {
    font-size: 14px;
  }
`;

export const DateTime = styled.span`
  margin-top: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.lightGray};

  @media ${({ theme }) => theme.media.desctop} {
    font-size: 14px;
  }
`;

export const Arrow = styled.button`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff;
  transition: .1s;
  &:disabled{
    opacity: .2;
  }
`;
export const ArrowCotainer = styled.div<IArrow>`
  align-items: center;
  height: 100%;
  width: 40px;
  display: none;
  ${({ type }) => type === 'left' ? "justify-content: flex-start;" : "justify-content: flex-end;"}
  
  @media ${({ theme }) => theme.media.laptop} {
    display: flex;
  }
`;

export const Copyright = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px;
  font-size: 12px;
  color: #FFF;
  background: rgba(0, 0, 0, .3);
  border-radius: 5px;
  transition-duration: .3s;

  &:hover {
    background: rgba(0, 0, 0, .7);
  }
`;
